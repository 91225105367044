import React from "react";
import SvgImage from "../../../components/image/svg-image";

const EcLink = () => {
  return (
    <div id="ecLink" className="ec-link">
      {/*<div className="link-text">*/}
      {/*  <SvgImage*/}
      {/*    filename="link-text"*/}
      {/*    alt="Webよりご購入頂いた方先着50名様バブーシュバッグプレゼント中"*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="link-btn">
        <a
          href="https://www.fatimamorocco.com/view/category/babouche"
          target={"_blank"}
          rel={"noopener noreferrer"}
          className="inner"
        >
          <div className="icon">
            <SvgImage filename="bag" alt="" />
          </div>
          <p className="btn-text">バブーシュの購入はこちら</p>
        </a>
      </div>
    </div>
  );
};

export default EcLink;
