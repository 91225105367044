import React from "react";
import PropTypes from "prop-types";
import Footer from "../../components/layout/common/footer";
import "@fontsource/noto-sans-jp";
import "@fontsource/zen-kaku-gothic-antique";
import "@fontsource/kumbh-sans";

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
