import React, { useRef, useEffect, useCallback } from "react";
import Layout from "../components/layout/layout";
import "../styles/PC/index.scss";
import "../styles/SP/index.scss";
import SvgImage from "../components/image/svg-image";
import NormalImage from "../components/image/normal-image";
import Carousel from "../components/layout/common/carousel";
import EcLink from "../components/layout/common/ec-link";
import Helmet from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import videoSrc from "../video/point2-mov.mp4";
import InstagramPost from "../components/layout/common/instagram-post";

const IndexPage = () => {
  const videoRef = useRef(null);
  const isRunning = useRef(false);
  const moveStarted = useRef(false);
  // const [isDisplay, setIsDisplay] = useState(false);

  const throttle = (callbackFn, limit) => {
    let wait = false;
    return function () {
      if (!wait) {
        callbackFn.call();
        wait = true;
        setTimeout(function () {
          wait = false;
        }, limit);
      }
    };
  };

  // const isScrollToggle = useCallback(() => {
  //   if (isRunning.current) return;
  //   isRunning.current = true;
  //   // const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  //   let videoIndex = document
  //     .getElementById("video")
  //     .getBoundingClientRect().top;
  //   requestAnimationFrame(() => {
  //     if (videoIndex < 300) {
  //       if (!moveStarted.current) {
  //         videoRef.current.play();
  //         moveStarted.current = true;
  //       }
  //
  //       console.log("play");
  //     }
  //     isRunning.current = false;
  //   });
  // }, []);
  //
  // useEffect(() => {
  //   document.addEventListener("scroll", throttle(isScrollToggle, 100), {
  //     passive: true,
  //   });
  //   return () => {
  //     document.removeEventListener("scroll", throttle(isScrollToggle, 100), {
  //       passive: true,
  //     });
  //   };
  // }, []);

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title="ファティマモロッコ バブーシュ"
      >
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-KMHKBDR"></script>
      </Helmet>
      <main className="main">
        <div className="top-contents-wrap">
          <div className="decoration-layer">
            <div className="circle pink size-01"></div>
            <div className="logo">
              <NormalImage filename="logo_symbol" alt="" />
            </div>
            <div className="deco deco-01">
              <NormalImage filename="img_bbs_09_rl" alt="" />
            </div>
            <div className="circle yellow size-02"></div>
            <div className="circle beige size-03"></div>
            <div className="deco deco-02">
              <NormalImage filename="img_bbs_11_lr" alt="" />
            </div>
            <div className="deco deco-03">
              <NormalImage filename="il_hand" alt="" />
            </div>
            <div className="circle gray size-04"></div>
            <div className="deco deco-04">
              <NormalImage filename="img_bbs_01_lr" alt="" />
            </div>
            <div className="circle beige size-05"></div>
            <div className="deco deco-05">
              <NormalImage filename="il_tree" alt="" />
            </div>
            <div className="circle yellow size-06"></div>
          </div>
          <div className="contents">
            <div className="btn">
              <a
                href="https://www.fatimamorocco.com/view/category/babouche"
                target={"_blank"}
                rel={"noopener noreferrer"}
                className="inner"
              >
                <span className="img">
                  <SvgImage filename="bag" alt="バブーシュを購入する" />
                </span>
                <p className="btn-text">
                  <span className="pc-none">バブーシュを購入する</span>
                  <span className="sp-none">バブーシュの購入はこちら</span>
                </p>
              </a>
            </div>
            <h1 className="title">
              <SvgImage
                filename="logo_title"
                alt="FATIMA MOROCCO の バブーシュ BABOUCHE"
              />
            </h1>
            <h2 className="catch">
              <SvgImage
                filename="mv_catch"
                alt="思わずニッコリ！快適なおうち時間"
              />
            </h2>
          </div>
        </div>

        <div className="introductory-contents-wrap">
          <p className="first-sentence">
            ずっと家にいると
            <br />
            なんかストレス感じる…
          </p>
          <div className="line">
            <SvgImage filename="thin_line" alt="" />
          </div>
          <div className="introductory-sentence">
            <p>家族とちょっとしたことで言い合いになり、なんか雰囲気悪い</p>
            <p>子供の走る音が気になり、テレワークに集中できない</p>
            <p>家ではリラックスして快適に過ごしたい</p>
          </div>
        </div>

        <div className="circle-contents-wrap">
          <div className="text-wrap">
            <div className="inner-text">
              <SvgImage
                filename="circle_inner_text"
                alt="家族で過ごすおうち時間思わずみんなでニッコリしちゃう"
              />
            </div>
            <div className="inner-title">
              <SvgImage
                filename="logo_title"
                alt="FATIMA MOROCCO の バブーシュ BABOUCHE"
              />
            </div>
          </div>
          <div className="contents">
            <div className="circle gray type-01"></div>
            <div className="circle pink type-02">
              <div className="inner-img-bbs">
                <NormalImage filename="il_babouche" alt="" />
              </div>
            </div>
            <div className="circle beige type-03">
              <div className="inner-img-pot">
                <NormalImage filename="il_pot" alt="" />
              </div>
            </div>
            <div className="circle photo-01">
              <NormalImage filename="design_smile01" alt="" />
            </div>
            <div className="circle photo-02">
              <NormalImage filename="design_cat01" alt="" />
            </div>
          </div>
        </div>

        <EcLink />

        <div id="about" className="about-contents-wrap">
          <div className="title-wrap">
            <h3 className="contents-title">
              <SvgImage filename="about_babouche" alt="About BABOUCHE" />
            </h3>
            <div className="line">
              <SvgImage filename="thin_line" alt="" />
            </div>
          </div>
          <p className="description">
            バブーシュは、伝統的なモロッコの革製スリッパ。かかとを踏んで履きます。モロッコでは外履きですが、日本ではルームシューズとして人気。マラケシュやフェズなど、地域によって異なるデザインも魅力です。一般的には山羊・羊・牛・ラクダなどの革から作られますが、Fatima
            Morocco（ファティマ
            モロッコ）では、表面の毛穴が目立たず、なめらかでや柔らかい上質の羊革を使用することにこだわっています。100%自社工房生産で、革の色付けや絵柄描き、刺繍、縫製など、数多くの職人による気が遠くなるような行程を経て、1足の美しいバブーシュができあがります。
          </p>
        </div>

        <Carousel />

        <div className="quality-contents-wrap">
          <div className="title-block">
            <div className="img">
              <NormalImage filename="il_forest" alt="" />
            </div>
            <div className="title-wrap">
              <p className="title">
                <SvgImage filename="about_quality" alt="About QUALITY" />
              </p>
              <div className="line">
                <SvgImage filename="thick_line" alt="" />
              </div>
            </div>
          </div>
          <div className="text-wrap">
            <p className="sub-title">
              <SvgImage
                filename="text_kodawari"
                alt="Fatima Moroccoのこだわり"
              />
            </p>
            <p className="text">
              売上げ40万足達成！　話題のTVドラマでも採用されたFatima
              Morocco（ファティマ モロッコ）のバブーシュ。
              <br className="pc-none" />
              大人気のヒミツをご紹介します。
            </p>
          </div>
        </div>

        <div className="points-contents-wrap">
          <div className="circle-wrap">
            <div className="circle gray type-01"></div>
            <div className="circle yellow type-02"></div>
            <div className="circle beige type-03"></div>
            <div className="circle photo">
              <NormalImage filename="design_smile02" alt="" />
            </div>
          </div>
          <div className="points-contents">
            <p>
              <span className="point">Point 1</span>100% オリジナルバブーシュ
            </p>
            <p>
              <span className="point">Point 2</span>100% 自社工房生産
            </p>
            <p>
              <span className="point">Point 3</span>厳選した良質な羊革
            </p>
            <p>
              <span className="point">Point 4</span>フカフカのソール
            </p>
            <p>
              <span className="point">Point 5</span>足を優しく包み込む丸み
            </p>
          </div>
        </div>

        {/* CONTENTS Point詳細 */}
        <div className="points-details-list">
          <div className="details-wrap">
            <div className="title-wrap">
              <p className="point">Point 1</p>
              <p className="title p1-ttl">
                <SvgImage
                  filename="text_original"
                  alt="100% オリジナルバブーシュ"
                />
              </p>
            </div>
            <div className="details">
              <p className="text">
                Fatima Morocco（ファティマ
                モロッコ）のバブーシュは100%オリジナル
                アイテムです。モロッコは、アフリカ大陸に息づく民族文化、フランスのスタイリッシュなエスプリ、そして神秘的なイスラム文化が混じり合う場所。独特の伝統工芸品が数多く存在します。Fatima
                Morocco（ファティマ
                モロッコ）のバブーシュはそんな魅力に溢れたアイテムばかり。現地の職人たちがひとつひとつ手作業で作り上げたもの。他には絶対にない100%オリジナル！
              </p>
              <div className="img-wrap">
                <div className="circle pink type-01"></div>
                <div className="circle yellow type-02"></div>
                <div className="photo-type01">
                  <NormalImage filename="tile01" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="details-wrap">
            <div className="details">
              <div className="text-wrap">
                <p className="point">Point 2</p>
                <p className="title p2-ttl">
                  <SvgImage
                    filename="text_jisyaseisan"
                    alt="100% 自社工房生産"
                  />
                </p>
                <p className="text">
                  Fatima Morocco（ファティマ モロッコ）のバブーシュは100%
                  モロッコにある自社工房生産です。革の色付けや絵柄描き、刺繍、縫製など、数多くの職人による気が遠くなるような行程を経て、全てハンドメイドの1足の美しいバブーシュができあがります。
                  <span className="sp-none note">
                    ファティマ
                    モロッコのバブーシュ工房は、マラケシュのメディナ（旧市街）にあります。
                  </span>
                </p>
                <div className="link sp-none">
                  <a
                    href="https://www.fatimamorocco.com/blog/2023/09/27/column01"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    FatimaMoroccoの
                    <br />
                    バブーシュが出来るまで
                  </a>
                </div>
              </div>
              <div className="media-wrap">
                <div className="circle beige type-03"></div>
                <div className="circle gray type-04"></div>
                <div className="circle gray type-05"></div>
                <div className="circle beige type-06"></div>
                <div className="media">
                  <iframe id="video" width="100%" height="100%"
                          src="https://www.youtube.com/embed/bvaIfUNlUwI?autoplay=1&mute=1&loop=1&playlist=bvaIfUNlUwI"
                          title="ファティマ モロッコのバブーシュが出来るまで" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                  {/*<video*/}
                  {/*  id="video"*/}
                  {/*  controls*/}
                  {/*  ref={videoRef}*/}
                  {/*  // loop*/}
                  {/*  // paused*/}
                  {/*  muted*/}
                  {/*  width="100%"*/}
                  {/*  height="100%"*/}
                  {/*>*/}
                  {/*  <source src={videoSrc} type="video/mp4" />*/}
                  {/*  <p>ご使用のブラウザでは動画再生に対応していません</p>*/}
                  {/*</video>*/}
                </div>
                <p className="pc-none text">
                  ファティマ
                  モロッコのバブーシュ工房は、マラケシュのメディナ（旧市街）にあります。
                </p>
              </div>
            </div>

            <div className="decoration-contents">
              <div className="circle yellow type-01"></div>
              <div className="circle photo-type01">
                <NormalImage filename="workshop01" alt="" />
              </div>
              <div className="circle photo-type02">
                <NormalImage filename="workshop02" alt="" />
              </div>
              <div className="circle photo-type03">
                <NormalImage filename="workshop03" alt="" />
              </div>
              <div className="deco-01">
                <NormalImage filename="il_flag" alt="" />
              </div>
            </div>
            <div className="link pc-none">
              <a
                href="https://www.fatimamorocco.com/blog/2023/09/27/column01"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                FatimaMoroccoの
                <br />
                バブーシュが出来るまで
              </a>
            </div>
          </div>
          <div className="details-wrap">
            <div className="details">
              <div className="text-wrap">
                <p className="point">Point 3</p>
                <p className="title p3-ttl">
                  <SvgImage
                    filename="text_good_quality"
                    alt="厳選した良質な羊革"
                  />
                </p>
                <p className="text point3-text">
                  モロッコの昔ながらの製法でなめされた、表面の毛穴が目立たず、なめらかで柔らかい羊革を厳選しており、一年を通じて快適に履くことができます。
                </p>
              </div>
              <div className="img-wrap p3-images">
                <div className="circle gray type-07"></div>
                <div className="photo-type02">
                  <NormalImage filename="design_cat02" alt="" />
                </div>
                <div className="circle yellow type-08"></div>
              </div>
            </div>
          </div>
          <div className="details-wrap point4-contents">
            <div className="details">
              <div className="text-wrap">
                <p className="point">Point 4</p>
                <p className="title p4-ttl">
                  <SvgImage filename="text_sole" alt="フカフカのソール" />
                </p>
                <p className="text">
                  通常の2倍ある厚さのクッションを使用したフカフカのソールは、疲れにくく足音が気になりません。
                </p>
              </div>
              <div className="img-wrap">
                <div className="photo-type03">
                  <NormalImage filename="img_bbs_02" alt="" />
                </div>
              </div>
              <div className="circle beige type-09"></div>
              <div className="circle pink type-10"></div>
            </div>
          </div>
          <div className="details-wrap point5-contents">
            <div className="details">
              <div className="text-wrap">
                <p className="point">Point 5</p>
                <p className="title p5-ttl">
                  <SvgImage
                    filename="text_shape"
                    alt="足を優しく包み込む丸み"
                  />
                </p>
                <p className="text point4-text">
                  足の甲を優しく包み込む、丸みを帯びた立体的なシェープは足馴染みがとてもよいです。
                </p>
              </div>
              <div className="img-wrap">
                <div className="photo-type04">
                  <NormalImage filename="img_bbs_06_rl" alt="" />
                </div>
              </div>
              <div className="circle beige type-11"></div>
              <div className="circle yellow type-12"></div>
            </div>
          </div>
        </div>

        <div className="logo">
          <NormalImage filename="logo_symbol" alt="" />
        </div>
        <div className="about-babouche-contents">
          <div className="title-wrap">
            <h2>
              <SvgImage
                filename="logo_title"
                alt="FATIMA MOROCCO の バブーシュ BABOUCHE"
              />
            </h2>
            <div className="word">
              <SvgImage filename="text_toha" alt="とは？" />
            </div>
          </div>
          <p className="text">
            Fatima Morocco（ファティマ
            モロッコ）にしかないデザインのルームシューズ。
            <br className="sp-none" />
            スマイル バブーシュ、ゴールドやシルバーカラー
            バブーシュ、猫ちゃんバブーシュなど、これらみんなファティマ
            モロッコが元祖です。
          </p>
        </div>
        <div className="decoration-content">
          <SvgImage
            filename="decoration-content"
            alt="履いているだけでHAPPYになるバブーシュ"
          />
        </div>

        <Carousel />

        <div className="block-space"></div>

        <EcLink />

        <div className="info-contents">
          <div className="inner">
            <div className="block">
              <p className="block-name">サイズ</p>
              <p className="info-text">
                キッズサイズ・レディースサイズ・メンズサイズ家族みんなで楽しめます。
              </p>
              <dl className="size-info-list">
                <div className="wrap">
                  <dt>キッズサイズ</dt>
                  <dd>19cm前後</dd>
                </div>
                <div className="wrap">
                  <dt>レディースサイズ</dt>
                  <div className="desc-wrap">
                    <dd>Sサイズ／22cm前後</dd>
                    <dd>Mサイズ／23cm前後</dd>
                    <dd>Lサイズ／24cm前後</dd>
                  </div>
                </div>
                <div className="wrap">
                  <dt>メンズサイズ</dt>
                  <dd>27cm前後</dd>
                </div>
              </dl>
            </div>
            <div className="block">
              <p className="block-name">素材</p>
              <p className="info-text">
                厳選された羊革なので素足でも、靴下でも、一年を通して快適に履くことができます。パタパタとした音がしにくいため、足音が気になりません。足に馴染みやすく、履いているうちに良い風合いが出てきます。
                布地などに比べると丈夫です。
              </p>
            </div>
            <div className="block">
              <p className="block-name">用途</p>
              <p className="info-text">
                おうち時間以外にも、様々なシチュエーションで活躍します。
              </p>
              <ul className="use-list">
                <li>・飛行機内、ホテルなど旅行に</li>
                <li>・授業参観日、PTA会議など学校で</li>
                <li>・習い事、自宅サロンなどに</li>
                <li>・オフィス、ご自宅来客用に</li>
                <li>・エステ、マッサージサロンで</li>
                <li>・病院に入院時に</li>
                <li>・母の日、父の日、バースデープレゼントに</li>
              </ul>
              <p className="note">
                ※ファティマ モロッコのオリジナルバブーシュは室内履きです。
              </p>
            </div>
          </div>
        </div>

        <Carousel />

        <div className="block-space"></div>

        <EcLink />

        <div className="decoration-smile">
          <div className="inner-text">
            <SvgImage
              filename="smile_inner_text"
              alt="思わず家族みんなでニッコリしちゃうファティマ モロッコのバブーシュ"
            />
          </div>
          <div className="parts">
            <SvgImage filename="dec_smile" alt="" />
          </div>
          <div className="inner-title">
            <SvgImage
              filename="logo_title"
              alt="FATIMA MOROCCO の バブーシュ BABOUCHE"
            />
          </div>
        </div>

        <div className="appeal-wrap">
          <p className="note">
            快適なおうち時間を過ごしませんか？
            <br />
            スリッパ嫌いな子供も喜んで履いてくれますよ。
            <br />
            もう、子供の走る音が気にならずにテレワークに集中できます。
            <br />
            履くだけでなく素敵なインテリアにも。
            <br />
            来客にさりげなくおしゃれ感アピール！
          </p>
          <div className="decoration-wrap">
            <div className="decoration-circle">
              <SvgImage
                filename="text_parenthesis"
                alt="いつも頑張っている家族ファティマ モロッコのバブーシュでプチ贅沢しましょ!"
              />
            </div>
            <div className="circle photo01">
              <NormalImage filename="design_sequin01" alt="" />
            </div>
            <div className="circle photo02">
              <NormalImage filename="design_smile03" alt="" />
            </div>
          </div>
        </div>

        <EcLink />

        <div className="faq-contents-wrap">
          <div className="circle yellow type01"></div>
          <div className="circle beige type02"></div>
          <div className="circle gray type03"></div>
          <div className="circle yellow type04"></div>
          <div className="circle beige type05"></div>
          <div className="circle pink type06"></div>
          <div className="title-wrap">
            <h3 className="contents-title">
              <SvgImage filename="title_faq" alt="Q&A" />
            </h3>
            <div>
              <SvgImage filename="thick_line" alt="" />
            </div>
          </div>
          <div className="faq-list">
            <div className="block">
              <p className="question">Q. 臭いが気になりますが…</p>
              <p className="answer">
                A.
                人工的な防臭加工はあえてしておりませんので、天然革独特の匂いがします。
                <br />
                ファティマ
                モロッコでは、モロッコの伝統的な製法でなめされた上質な羊革を厳選して使用しています。
                <br />
                特にご購入直後は匂いがきつく感じられる場合がございますが、ご使用いただくうちに薄れてまいります。
                <br />
                温度や湿度により匂いの度合いが異なりますが、気になる場合は風通しの良い場所で陰干しすることをおすすめいたします。
                <br />
                人工的な消臭剤などのご使用は、逆効果になる恐れがありますのでお避けください。
                <br />
                保管場所はできるだけ日の当たらない風通しの良い場所をお選びください。
              </p>
            </div>
            <div className="block">
              <p className="question">
                Q. キズの様なものや、しわがあるのですが…
              </p>
              <p className="answer">
                A.
                厳しいチェックをしておりますが、元からある革の小さな擦れやしわ、斑点などがあります。これらは天然素材とハンドメイドの証でもありますので、ご了承下さい。
              </p>
            </div>
            <div className="block">
              <p className="question">Q. 左右の大きさが若干違うのですが…</p>
              <p className="answer">
                A.
                バブーシュは組んだ状態で保管されているため、片方が広がっている様に感じる場合がありますが、ご使用いただくうちに馴染んできます。
                <br />
                ハンドメイドなため、サイズやお色が写真や表示と若干異なる場合がございます。
              </p>
            </div>
            <div className="block">
              <p className="question">
                Q. 内側に折り込まれているかかと部分が、剥がれてきたのですが…
              </p>
              <p className="answer">
                A.
                中敷部分は接着剤で貼り合わせておりますので、ご利用いただくうちに剥がれることがあります。
                <br />
                その際は革用接着剤で貼り合わせて下さい。
              </p>
            </div>
            <div className="block">
              <p className="question">Q. お手入れ方法を教えてください。</p>
              <p className="answer">
                A.
                革部分は汚れを防ぐため、無色の革用クリームでのお手入れをおすすめします。汚れてしまった場合は、固く絞った雑巾で軽く拭いた後、風通しの良い場所で自然乾燥させてください。
              </p>
            </div>
            <div className="block">
              <p className="question">Q. 他社に比べると価格が高いですが…</p>
              <p className="answer">
                A. Fatima Morocco（ファティマ モロッコ）のバブーシュは、100%
                モロッコにある自社工房生産です。革の色付けや絵柄描き、刺繍、縫製など、数多くの職人による気が遠くなるような行程を経て、全てハンドメイドの1足の美しいバブーシュができあがります。
                <br />
                この一連の生産コスト、輸入関税、日本への送料、日本についてからの通関料、倉庫までの送料、さらに検品代、1足ずつの袋入れ、値付け、倉庫代、さらには卸先への掛け率。それらを加味した価格になっております。
                <br />
                何人もの人の手で手間暇かけて1足ずつ、しっかりしたクオリティーで手作りされたバブーシュの価値になります。
              </p>
            </div>
            <div className="block">
              <p className="question">
                Q. “女性支援„を行なっていると聞いていますが…
              </p>
              <p className="answer">
                A.
                モロッコはまだまだ男尊女卑の世界。バブーシュだと革のカットや縫製は男性、刺繍などの細工を施すのが女性の仕事です。
                <br />
                が、イスラム教のモロッコでは男女が同じ部屋で仕事を行うことは一切ありませんし、仕事のトップに立つのは男性。
                <br />
                仕事は女性にも与えられていますが、その対価はツケにされて支払われないことが多いそうです。
                <br />
                そんな女性たちのディテールを施す腕は一級品で、中にはラグジュアリーメゾンのアパレルに携わっているほどの女性もいます。
                <br />
                そんな女性を探し、安心して働ける工房を作りました。納期を守って、こちらの思いを汲みながら丁寧に制作してくれる女性に対し、商品と引き換えに対価を現金でお支払いしています。
                <br />
                彼女たちは仕事を与えられ、私たちは納期内に素晴らしい商品を与えられる。共に
                Win-Win の良好な関係を築いています。
                <br />
                これからもその関係を続けたい。男性の影になってしまいがちな女性たちを支えたいと思っています。
              </p>
            </div>
          </div>
        </div>

        {/*<div className="pc-none">*/}
        {/*  <EcLink />*/}
        {/*</div>*/}

        <EcLink />

        <div className="company-contents">
          <h3 className="contents-title">
            <SvgImage filename="text_company" alt="About FATIMA MOROCCO" />
          </h3>
          <div className="link">
            <a
              href="https://www.fatimamorocco.com/"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              FatimaMoroccoの
              <br />
              ウェブサイトをみる
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
