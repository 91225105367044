import React, { useCallback, useState, useRef } from "react";
import NormalImage from "../../../components/image/normal-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

const Carousel = () => {
  const swiperRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const initialState = {
    bbs1: false,
    bbs2: false,
    bbs3: false,
    bbs4: false,
    bbs5: false,
    bbs6: false,
    bbs7: false,
    bbs8: false,
    bbs9: false,
    bbs10: false,
  };

  const [active, setActive] = useState(initialState);

  const classToggle = useCallback(
    (name) => {
      let swiperSelect = {
        bbs1: false,
        bbs2: false,
        bbs3: false,
        bbs4: false,
        bbs5: false,
        bbs6: false,
        bbs7: false,
        bbs8: false,
        bbs9: false,
        bbs10: false,
      };
      let clickIndex = Number.parseInt(name.replaceAll("bbs", ""));
      swiperRef.current.swiper.autoplay.stop();
      swiperSelect[name] = !active[name];
      setActive(swiperSelect);
      swiperRef.current.swiper.slideToLoop(clickIndex - 1);
    },
    [active]
  );

  const classNext = useCallback(() => {
    swiperRef.current.swiper.autoplay.stop();
    let swiperSelect = {
      bbs1: false,
      bbs2: false,
      bbs3: false,
      bbs4: false,
      bbs5: false,
      bbs6: false,
      bbs7: false,
      bbs8: false,
      bbs9: false,
      bbs10: false,
    };

    // 取れるのは動いた後のIndexみたい
    let realIndex = swiperRef.current.swiper.realIndex;
    if (active["bbs" + (realIndex > 0 ? realIndex : 10)]) {
      // センターにあったスライドがアクティブの場合
      swiperSelect["bbs" + ((realIndex % 10) + 1)] = true;
    }
    setActive(swiperSelect);
    swiperRef.current.swiper.slideNext();
  }, [active]);

  const classPrev = useCallback(() => {
    swiperRef.current.swiper.autoplay.stop();
    let swiperSelect = {
      bbs1: false,
      bbs2: false,
      bbs3: false,
      bbs4: false,
      bbs5: false,
      bbs6: false,
      bbs7: false,
      bbs8: false,
      bbs9: false,
      bbs10: false,
    };

    // 取れるのは動いた後のIndexみたい
    let realIndex = swiperRef.current.swiper.realIndex;
    if (active["bbs" + (realIndex < 9 ? realIndex + 2 : 1)]) {
      // センターにあったスライドがアクティブの場合
      swiperSelect["bbs" + (realIndex + 1)] = true;
    }
    setActive(swiperSelect);
    swiperRef.current.swiper.slidePrev();
  }, [active]);

  return (
    <div id="carousel" className="carousel">
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, Navigation]}
        slidesPerView={2.1}
        loopAdditionalSlides={10 - 2.1}
        breakpoints={{
          1200: {
            slidesPerView: 5,
          },
        }}
        centeredSlides
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        className="productSwiper"
      >
        <SwiperSlide
          onClick={() => {
            classToggle("bbs1");
          }}
          className={active.bbs1 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs1")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_01_rl" alt="スマイルサブラ　イエロー" />
          </div>
          <p className="price">￥4,400〜￥5,500</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000190"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000065"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs2");
          }}
          className={active.bbs2 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs2")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_02_rl" alt="スマイル刺繡　ホワイト" />
          </div>
          <p className="price">￥5,500〜￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000031"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000048"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs3");
          }}
          className={active.bbs3 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs3")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_03_rl" alt="サブラスパンコール　ホワイト" />
          </div>
          <p className="price">￥4,400~￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000359"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000350"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000339"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs4");
          }}
          className={active.bbs4 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs4")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_04_rl" alt="猫刺繡　ピンク" />
          </div>
          <p className="price">￥4,400~￥6,050</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000303"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000307"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs5");
          }}
          className={active.bbs5 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs5")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_05_rl" alt="スマイルスパンコール　ホワイト" />
          </div>
          <p className="price">￥4,400~￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000012"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000045"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000060"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs6");
          }}
          className={active.bbs6 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs6")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_06_rl" alt="タッセル　グレー" />
          </div>
          <p className="price">￥4,400〜￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000226"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000222"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000218"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs7");
          }}
          className={active.bbs7 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs7")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_13_rl" alt="ファティマハンド刺繍　ナチュラル" />
          </div>
          <p className="price">￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000689"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs8");
          }}
          className={active.bbs8 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs8")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_08_rl" alt="サブラスパンコール　ブラック" />
          </div>
          <p className="price">￥4,400~￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000355"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000348"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000335"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs9");
          }}
          className={active.bbs9 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs9")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_09_rl" alt="猫刺繍　ホワイト" />
          </div>
          <p className="price">￥4,400~￥7,150</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000305"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000301"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              メンズ
            </a>
            <a
              href="https://www.fatimamorocco.com/view/item/000000000309"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              キッズ
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide
          onClick={() => {
            classToggle("bbs10");
          }}
          className={active.bbs10 ? "focus" : ""}
        >
          <button onClick={() => classToggle("bbs10")} className="close-btn">
            <span></span>
            <span></span>
          </button>
          <div className="img">
            <NormalImage filename="img_bbs_12_rl" alt="ファティマハンド　グリーン" />
          </div>
          <p className="price">￥5,500</p>
          <div className="size-lineup">
            <a
              href="https://www.fatimamorocco.com/view/item/000000000589"
              className="size"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              レディース
            </a>
          </div>
        </SwiperSlide>
        <div
          className="swiper-button-prev"
          ref={navigationPrevRef}
          onClick={() => classPrev()}
        ></div>
        <div
          className="swiper-button-next"
          ref={navigationNextRef}
          onClick={() => classNext()}
        ></div>
      </Swiper>
    </div>
  );
};

export default Carousel;
