import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const SvgImage = ({ alt, filename }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query svgQuery {
        allFile(filter: { extension: { in: ["svg"] } }) {
          nodes {
            relativePath
            name
            publicURL
          }
        }
      }
    `
  );
  return (
    <>
      <picture>
        <source
          srcSet={
            allFile.nodes.find(
              (n) => n.relativePath === "PC/" + filename + ".svg"
            ).publicURL
          }
          media="(min-width: 671px)"
        />
        <img
          src={
            allFile.nodes.find(
              (n) => n.relativePath === "SP/" + filename + ".svg"
            ).publicURL
          }
          alt={alt}
        />
      </picture>
    </>
  );
};

export default SvgImage;
