import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <a
        href="https://www.fatimamorocco.com//view/company"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        会社概要
      </a>
      <a
        href="https://www.fatimamorocco.com/blog/wholesale"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        卸売のご案内
      </a>
      <a
        href="https://www.fatimamorocco.com/view/contract"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        特定商取引法に関する表示
      </a>
      <a
        href="https://www.fatimamorocco.com/view/policy"
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        プライバシーポリシー
      </a>
      {/*<a*/}
      {/*  href="https://www.fatimamorocco.com/recruit.html"*/}
      {/*  target={"_blank"}*/}
      {/*  rel={"noopener noreferrer"}*/}
      {/*>*/}
      {/*  採用情報*/}
      {/*</a>*/}
      <small>Copyright &copy; Fatima Morocco</small>
    </footer>
  );
}

export default Footer;
